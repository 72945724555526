import React, { useState } from 'react';
import { Container, Grid, Box, Typography, Paper, Tab, Tabs, useMediaQuery} from '@mui/material';
import Charts from '../components/Charts'; // Adjust the path as per your file structure
import DesktopControllersList from '../components/desktop/ControllersList';
import DesktopLocationList from '../components/desktop/LocationsList';
import { useTranslation } from 'react-i18next';
import Map from '../components/Map';
import { styled } from '@mui/system';

const useStyles = styled((theme) => ({
  root: {
    flexGrow: 1,
    width: '100%',
    overflowX: 'auto',
    marginBottom: theme.spacing(2),
  },
  tab: {
    minWidth: 120,
  },
}));


const Home = ({direction}) => {
    const { t } = useTranslation(); // Assuming you are using translation keys
    const [desktopTabValue, setDesktopTabValue] = useState(0); // Initialize tab value state
    const [mobileTabValue, setMobileTabValue] = useState(0); // Initialize tab value state
    const [selectedController, setSelectedController] = useState(null); // State to track selected controller
    const isDesktop = useMediaQuery('(min-width: 1300px)'); // Adjust this breakpoint as needed
    const classes = useStyles();

    const handleDesktopTableChange = (event, newValue) => {
        setDesktopTabValue(newValue);
    };

    const handleMobileTableChange = (event, newValue) => {
      setMobileTabValue(newValue);
  };

    const handleControllerClick = (controllerId) => {
        setSelectedController(controllerId);
    };

    // Dummy data for controllers and graph (replace with actual data or components)
    const controllers = [
      { id: 1, name: 'Controller 1', location: 'Field A', battery: 75, normal: true },
      { id: 2, name: 'Controller 2', location: 'Field B', battery: 50, normal: false },
      { id: 3, name: 'Controller 3', location: 'Field C', battery: 90, normal: true },
      { id: 4, name: 'Controller 4', location: 'Field D', battery: 75, normal: false },
      { id: 5, name: 'Controller 5', location: 'Field E', battery: 50, normal: true },
      { id: 6, name: 'Controller 6', location: 'Field F', battery: 90, normal: false },
      { id: 7, name: 'Controller 7', location: 'Field G', battery: 75, normal: true },
      { id: 8, name: 'Controller 8', location: 'Field H', battery: 50, normal: false },
      { id: 9, name: 'Controller 9', location: 'Field I', battery: 90, normal: true },
      // Add more controllers as needed
    ];
    // dummy data for locations
    const locations = [
      { id: 1, name: 'Location 1', cropType: 'Tomatoes', locationType: 'Greenhouse', normal: true},
      { id: 2, name: 'Location 2', cropType: 'Lettuce', locationType: 'Field', normal: true},
      { id: 3, name: 'Location 3', cropType: 'Strawberries', locationType: 'Greenhouse', normal: true},
    ];

    const DesktopView = () => {
      const { t } = useTranslation();
    
      return (
        <>
          {/* Tabs */}
          <Grid item xs={12}>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
              <Tabs value={desktopTabValue} onChange={handleDesktopTableChange} aria-label="controllers-tab">
                <Tab label={<Typography variant="body1">{t('controllers')}</Typography>} />
                <Tab label={<Typography variant="body1">{t('locations')}</Typography>} />
              </Tabs>
            </Box>
          </Grid>
    
          {/* Tab panels */}
          <Grid item xs={12}>
            {desktopTabValue === 0 && (
              <Grid container spacing={3}>
                {/* Left panel - List of controllers */}
                <DesktopControllersList controllers={controllers}/>
                {/* Right panel - Graph (placeholder) */}
                <Grid item xs={12} md={8} sx={{ alignItems: 'center', justifyContent: 'center' }}>
                  <Paper elevation={3} sx={{ p: 2, height: '100%', alignItems:'center',justifyContent:'center'}}>
                    <Charts controllerId={selectedController} />
                  </Paper>
                </Grid>
              </Grid>
            )}
            {desktopTabValue === 1 && (
              <Grid container spacing={3}>
                {/* Left panel - List of locations */}
                <DesktopLocationList locations={locations}/>
                {/* Right panel - Map */}
                <Map MapWidth={8}/>
              </Grid>
            )}
          </Grid>
        </>
      );
    };
    
    const MobileView = () => {
      return (
        <Grid item xs={12}>
          <Box className={classes.root}>
            <Paper>
            <Tabs
              value={mobileTabValue} onChange={handleMobileTableChange}
              indicatorColor="primary"
              textColor="primary"
              variant="scrollable"
              scrollButtons="auto"
            >
            {desktopTabValue === 0 && 
              controllers.map((controller, index) => (
                <Tab key={index} label={<Typography variant="body1">{controller.name}</Typography>} className={classes.tab} />
              ))
            }
            {desktopTabValue === 1 && 
              locations.map((location, index) => (
                <Tab key={index} label={<Typography variant="body1">{location.name}</Typography>} className={classes.tab} />
              ))
            }
            </Tabs>
            {desktopTabValue === 0 && 
              // show controllers
              <Charts controllerId={selectedController} />
            }
            {desktopTabValue === 1 && 
              // show locations
              <>
              </>
            }
            </Paper>
          </Box>
        </Grid>
      );
    };

  const xsSize = isDesktop ? 12 : 12/2
  return (
    <>
    <Container maxWidth="lg"> 
        {isDesktop ? (
          <Box sx={{ mt: 4, mb: 4 }}>
          <Grid container spacing={3}>
            {/* Statistics boxes */}
            <Grid item xs={xsSize} sm={6} md={3}>
              <Paper elevation={3} sx={{ p: 2, height: '100%' }}>
                <Typography variant="h6" gutterBottom>
                  {t('controllers')}
                </Typography>
                <Typography variant="h4" component="h1">
                  10
                </Typography>
              </Paper>
            </Grid>
            <Grid item xs={xsSize} sm={6} md={3}>
              <Paper elevation={3} sx={{ p: 2, height: '100%' }}>
                <Typography variant="h6" gutterBottom>
                  {t('locations')}
                </Typography>
                <Typography variant="h4" component="h1">
                  5
                </Typography>
              </Paper>
            </Grid>
            <Grid item xs={xsSize} sm={6} md={3}>
              <Paper elevation={3} sx={{ p: 2, height: '100%' }}>
                <Typography variant="h6" gutterBottom>
                  {t('data')}
                </Typography>
                <Typography variant="h4" component="h1">
                  25
                </Typography>
              </Paper>
            </Grid>
            <Grid item xs={xsSize} sm={6} md={3}>
              <Paper elevation={3} sx={{ p: 2, height: '100%' }}>
                <Typography variant="h6" gutterBottom>
                  {t('alerts')}
                </Typography>
                <Typography variant="h4" component="h1">
                  3
                </Typography>
              </Paper>
            </Grid>
          </Grid>
        <Grid container spacing={3}>
          <DesktopView/>
          </Grid>
          </Box>
        ) : (
          null
        )}
    </Container>

    <Grid container spacing={3}>
        {!isDesktop ? (
          <MobileView/>
        ) : (
          null
        )}
      </Grid>
    </>
  );
};

export default Home;