import React, { useState, useEffect } from 'react';
import {
  Box, Typography, TextField, FormControl, InputLabel, Select, MenuItem, Button, Dialog, DialogTitle, DialogContent, DialogActions, Paper
} from '@mui/material';
import DatePicker from 'react-datepicker'; // Import DatePicker from react-datepicker
import 'react-datepicker/dist/react-datepicker.css'; // Import default styles

const stages = [
  'soil_preparation', 'seedling_preparation', 'transplanting_preparation', 'planting', 'fertilizing', 'pruning', 'harvesting', 'maintenance'
];

const blocks = [
  'Block F19', 'Block R11', 'Block E23', 'Block L30' // Example options for blocks
];

const crops = [
  'Eggplant', 'Tomato (Heirloom)', 'Tomato (Cherry)' // Example options for crops
];


const AddTaskModal = ({ open, onClose, onSave }) => {
  const [block, setBlock] = useState('');
  const [crop, setCrop] = useState('');
  const [stage, setStage] = useState('');
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [urgent, setUrgent] = useState(false);

  useEffect(() => {
    const currentMonth = new Date().getMonth();
    if (startDate && endDate) {
      const startMonth = new Date(startDate).getMonth();
      const endMonth = new Date(endDate).getMonth();
      setUrgent(startMonth === currentMonth || endMonth === currentMonth);
    }
  }, [startDate, endDate]);

  const handleSave = () => {
    const newTask = {
      block,
      crop,
      stage,
      startDate: startDate ? new Date(startDate).getTime() : null,
      endDate: endDate ? new Date(endDate).getTime() : null,
      urgent
    };
    onSave(newTask);
    onClose();
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth="xs" fullWidth>
      <Paper elevation={3} sx={{ p: 3 }}>
        <DialogTitle>Add New Task</DialogTitle>
        <DialogContent>
          <FormControl variant="outlined" fullWidth sx={{ marginBottom: 2 }}>
            <InputLabel>Block</InputLabel>
            <Select
              value={block}
              onChange={(e) => setBlock(e.target.value)}
              label="Block"
            >
              {blocks.map((blockOption) => (
                <MenuItem key={blockOption} value={blockOption}>{blockOption}</MenuItem>
              ))}
            </Select>
          </FormControl>
          <FormControl variant="outlined" fullWidth sx={{ marginBottom: 2 }}>
            <InputLabel>Crop</InputLabel>
            <Select
              value={crop}
              onChange={(e) => setCrop(e.target.value)}
              label="Crop"
            >
              {crops.map((cropOption) => (
                <MenuItem key={cropOption} value={cropOption}>{cropOption}</MenuItem>
              ))}
            </Select>
          </FormControl>
          <FormControl variant="outlined" fullWidth sx={{ marginBottom: 2 }}>
            <InputLabel>Stage</InputLabel>
            <Select
              value={stage}
              onChange={(e) => setStage(e.target.value)}
              label="Stage"
            >
              {stages.map((stageOption) => (
                <MenuItem key={stageOption} value={stageOption}>{stageOption}</MenuItem>
              ))}
            </Select>
          </FormControl>
          <Box sx={{ display: 'flex', marginBottom: 2 }}>
            <Typography variant="body1" sx={{ marginRight: 2 }}>Start Date:</Typography>
            <DatePicker
              selected={startDate}
              onChange={(date) => setStartDate(date)}
              dateFormat="yyyy-MM-dd"
              className="form-control"
            />
          </Box>
          <Box sx={{ display: 'flex', marginBottom: 2 }}>
            <Typography variant="body1" sx={{ marginRight: 2 }}>End Date:</Typography>
            <DatePicker
              selected={endDate}
              onChange={(date) => setEndDate(date)}
              dateFormat="yyyy-MM-dd"
              className="form-control"
            />
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose} color="secondary">
            Cancel
          </Button>
          <Button onClick={handleSave} color="primary">
            Save
          </Button>
        </DialogActions>
      </Paper>
    </Dialog>
  );
};

export default AddTaskModal;