import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import { Box, CssBaseline, AppBar, Toolbar, IconButton, Typography, useTheme, useMediaQuery, ThemeProvider } from '@mui/material';
import { Menu } from '@mui/icons-material';
import { CacheProvider } from '@emotion/react';
import createCache from '@emotion/cache';
import SideNav from './components/navigation/SideNav';
import ProfileMenu from './components/navigation/ProfileMenu';
import LanguageMenu from './components/navigation/LanguageMenu';
import NotificationMenu from './components/navigation/NotificationMenu';
import { useTranslation } from 'react-i18next';
import { createTheme, StyledEngineProvider } from '@mui/material/styles'; // Import StyledEngineProvider
import { ThemeProvider as EmotionThemeProvider } from '@emotion/react';
import AppRoutes from './routes'; // Import the routes
import Login from './pages/Login'; // Import the Login component
import theme from './assets/theme';
import BottomNav from './components/navigation/BottomNav';

const createEmotionCache = (direction) => {
  return createCache({
    key: direction === 'rtl' ? 'mui-rtl' : 'mui',
    prepend: false,
  });
};

const drawerWidth = 240;

const App = () => {
  const [mobileOpen, setMobileOpen] = useState(false);
  //const theme = useTheme();
  const isMdUp = useMediaQuery(theme.breakpoints.up('md'));
  const { t, i18n } = useTranslation();
  const [direction, setDirection] = useState('ltr');
  const [cache, setCache] = useState(createEmotionCache('ltr'));
  const [isAuthenticated, setIsAuthenticated] = useState(false); // Authentication state
  const [permissions, setPermissions] = useState({ // Permissions state
    admin: true,
    main: true,
    packagingHouse: true,
    humanResources: true,
    account: true,
    settings: true,
    fieldWork: true
  });

  const toggleLanguage = async (language) => {
    let newDirection = 'ltr';
    if (language === 'ar' || language === 'he') {
      newDirection = 'rtl';
    }
    setDirection(newDirection);
    document.body.setAttribute('dir', newDirection);
    setCache(createEmotionCache(newDirection));
    await i18n.changeLanguage(language);
  };

  useEffect(() => {
    const currentLanguage = i18n.language;
    toggleLanguage(currentLanguage);
  }, [i18n.language]);

  const themeWithDirection = createTheme({
    direction: direction,
    ...theme,
  });

  return (
    <StyledEngineProvider injectFirst>
      <CacheProvider value={cache}>
        <EmotionThemeProvider theme={themeWithDirection}>
          <Router>
            <Box sx={{ display: 'flex', flexDirection: 'column', minHeight: '100vh', direction: direction }}>
              <CssBaseline />
              {isAuthenticated && (
                <>
                  <AppBar
                    position="fixed"
                    sx={{
                      zIndex: theme.zIndex.drawer + 1, // Ensure AppBar is above the drawer
                      ml: { sm: direction === 'rtl' ? 0 : `${drawerWidth}px` },
                      width: { sm: `calc(100% - ${drawerWidth}px)` },
                      ...(direction === 'rtl' && { mr: { sm: `${drawerWidth}px` } }),
                    }}
                  >
                    <Toolbar>
                      {!isMdUp && (
                        <IconButton
                          color="inherit"
                          aria-label="open drawer"
                          edge="start"
                          onClick={() => setMobileOpen(!mobileOpen)}
                          sx={{ mr: 2 }}
                        >
                          <Menu />
                        </IconButton>
                      )}
                      <Typography variant="h6" noWrap component="div" sx={{ flexGrow: 1 }}>
                        {t('dashboard')}
                      </Typography>
                      <NotificationMenu />
                      <LanguageMenu />
                      <ProfileMenu />
                    </Toolbar>
                  </AppBar>
                  {!isMdUp &&
                    <BottomNav />
                  }
                  <SideNav 
                    mobileOpen={mobileOpen} 
                    handleDrawerToggle={() => setMobileOpen(!mobileOpen)} 
                    direction={direction} 
                    permissions={permissions} // Pass permissions to SideNav
                  />
                </>
                
              )}
              <Box
                component="main"
                sx={{
                  flexGrow: 1,
                  p: 3,
                  pt: isAuthenticated ? `${theme.spacing(8)}` : '0', // Offset the main content by the height of the AppBar
                  ml: isAuthenticated ? { sm: `${drawerWidth}px` } : '0',
                  mr: isAuthenticated ? { sm: `${drawerWidth}px` } : '0',
                  width: isAuthenticated ? { sm: `calc(100% - ${drawerWidth}px)` } : '100%',
                }}
              >
                <Routes>
                  <Route path="/login" element={<Login setAuth={setIsAuthenticated} direction={direction} />} />
                  <Route path="/*" element={isAuthenticated ? <AppRoutes direction={direction} /> : <Navigate to="/login" />} />
                </Routes>
              </Box>
            </Box>
          </Router>
        </EmotionThemeProvider>
      </CacheProvider>
    </StyledEngineProvider>
  );
};

export default App;