import React from 'react';
import { Drawer, List, ListItem, ListItemIcon, ListItemText, Box, Typography, Divider, IconButton, Menu } from '@mui/material';
import { 
    Home, 
    Person, 
    Settings,
    SettingsRemote,
    ShareLocation,
    Payment,
    QrCode,
    AccessTime,
    LocalShipping,
    Timeline
} from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

const drawerWidth = 240;

const SideNav = ({ mobileOpen, handleDrawerToggle, direction, permissions }) => {
  const isRTL = direction === 'rtl';
  const { t } = useTranslation(); // Assuming you are using translation keys
  const navigate = useNavigate();

  const categories = [
    { id: 'main', label: t('main'), items: [
      { icon: <Home style={{ color: 'white' }} />, text: <Typography variant="body1">{t('home')}</Typography>, route: '/' },
      { icon: <ShareLocation style={{ color: 'white' }} />, text: <Typography variant="body1">{t('locations')}</Typography>, route: '/locations' },
      //{ icon: <SettingsRemote style={{ color: 'white' }} />, text: t('controllers'), route: '/controllers' },
    ] },
    { id: 'packagingHouse', label: t('packagingHouse'), items: [
        //{ icon: <LocalShipping style={{ color: 'white' }} />, text: t('distribution'), route: '/distribution' },
        { icon: <QrCode style={{ color: 'white' }} />, text: <Typography variant="body1">{t('barcodes')}</Typography>, route: '/barcodes' },
    ] },
    { id: 'fieldWork', label: t('fieldWork'), items: [
      { icon: <Timeline style={{ color: 'white' }}/>, text: <Typography variant="body1">{t('workTimeline')}</Typography>, route: '/field/timeline' }
    ] },
    //{ id: 'humanResources', label: t('humanResources'), items: [
    //{ icon: <AccessTime style={{ color: 'white' }} />, text: t('attendance'), route: '/attendance' }
    //] },
    { id: 'account', label: t('account'), items: [
      { icon: <Person style={{ color: 'white' }} />, text: <Typography variant="body1">{t('profile')}</Typography>, route: '/profile' },
      { icon: <Payment style={{ color: 'white' }} />, text: <Typography variant="body1">{t('payment')}</Typography>, route: '/payments' }
    ] },
    //{ id: 'settings', label: t('settings'), items: [
    //  { icon: <Settings style={{ color: 'white' }} />, text: t('generalSettings'), route: '/settings' }
    //] },
    { id: 'admin', label: t('admin'), items: [
      { icon: <Settings style={{ color: 'white' }} />, text: <Typography variant="body1">{t('dashboard')}</Typography>, route: '/admin/dashboard' }
    ] }
  ];

  const drawer = (
    <div>
      <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', p: 2, textAlign: isRTL ? 'right' : 'left'}}>
        <Typography variant="h4" component="div" sx={{ color: 'white', textAlign: isRTL ? 'right' : 'left' }}>
          OmnIoT
        </Typography>
        <IconButton onClick={handleDrawerToggle}>
          <Menu style={{ color: 'white' }} />
        </IconButton>
      </Box>
      <Divider />
      {categories.map((category) => (
        permissions[category.id] && (
          <div key={category.id}>
            <Typography variant="overline" sx={{ color: 'white', pl: 2, textAlign: isRTL ? 'right' : 'left'}}>
              {category.label}
            </Typography>
            <List>
              {category.items.map((item, index) => (
                <ListItem button key={index} sx={{ textAlign: isRTL ? 'right' : 'left'}} onClick={() => navigate(item.route)}>
                  <ListItemIcon>{item.icon}</ListItemIcon>
                  <ListItemText primary={item.text} />
                </ListItem>
              ))}
            </List>
            <Divider />
          </div>
        )
      ))}
    </div>
  );

  return (
    <Box component="nav">
      {/* Temporary Drawer for mobile */}
      <Drawer
        variant="temporary"
        open={mobileOpen}
        anchor={direction === "rtl" ? 'right' : 'left'}
        onClose={handleDrawerToggle}
        ModalProps={{
          keepMounted: true, // Better open performance on mobile.
        }}
        sx={{
          display: { xs: 'block', sm: 'block', md: 'none' },
          '& .MuiDrawer-paper': {
            boxSizing: 'border-box',
            width: drawerWidth,
            backgroundColor: '#1e90ff',
            color: 'white',
            flexDirection: 'column', // Reverse direction for RTL layout
            flex: '1',
          },
        }}
      >
        {drawer}
      </Drawer>

      {/* Permanent Drawer for desktop */}
      <Drawer
        variant="permanent"
        anchor={direction === "rtl" ? 'right' : 'left'}
        open
        sx={{
          display: { xs: 'none', sm: 'none', md: 'block' },
          '& .MuiDrawer-paper': {
            boxSizing: 'border-box',
            width: drawerWidth,
            backgroundColor: '#1e90ff',
            color: 'white',
          },
        }}
      >
        {drawer}
      </Drawer>
    </Box>
  );
};

export default SideNav;