import React, { useState } from 'react';
import { Grid, Paper, Typography, Card, CardContent, Button, Box } from '@mui/material';
import { useTranslation } from 'react-i18next';

const handleControllerClick = (id) => {
  console.log(`Controller ${id} clicked`);
};

const ControllersList = ({controllers}) => {
  const { t } = useTranslation(); // Assuming you are using translation keys
  const [selectedState, setSelectedState] = useState(true); // Initial state set to 'normal' (true)
  const [controllersObject,setControllersObject] = useState(controllers);
  const filteredControllers = controllersObject.filter(controller => controller.normal === selectedState);

  return (
    <Grid item xs={12} md={4}>
      <Paper elevation={3} sx={{ height: 'calc(70vh)', overflowY: 'auto', p: 2 }}>
        <Grid container spacing={2} sx={{ mb: 2 }}>
          <Grid item xs={6}>
            <Button
              fullWidth
              variant={selectedState ? "contained" : "outlined"} // Use selectedState to determine the variant
              onClick={() => setSelectedState(true)} // Set selectedState to true for 'normal'
            >
              {t('normal')}
            </Button>
          </Grid>
          <Grid item xs={6}>
            <Button
              fullWidth
              variant={selectedState ? "outlined" : "contained"} // Use selectedState to determine the variant
              onClick={() => setSelectedState(false)} // Set selectedState to false for 'abnormal'
            >
              {t('abnormal')}
            </Button>
          </Grid>
        </Grid>
        <Grid container spacing={2}>
          {filteredControllers.map((controller) => (
            <Grid item xs={12} key={controller.id}>
              <Card sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', p: 1 }}>
                <CardContent>
                  <Typography variant="subtitle1" component="div">
                    {controller.name}
                  </Typography>
                  <Typography variant="body2" color="textSecondary">
                    {t('location')}: {controller.location}
                  </Typography>
                </CardContent>
                <CardContent>
                  <Typography variant="body2" color="textSecondary">
                    {t('battery')}
                  </Typography>
                  <Typography variant="subtitle1">
                    {controller.battery}%
                  </Typography>
                </CardContent>
                <CardContent>
                  <Button 
                    variant="outlined" 
                    onClick={() => handleControllerClick(controller.id)}
                    sx={{ ml: 2, flex: 'none' }}
                  >
                    {t('view')}
                  </Button>
                </CardContent>
              </Card>
            </Grid>
          ))}
        </Grid>
      </Paper>
    </Grid>
  );
};

export default ControllersList;