// BottomNav.js
import React from 'react';
import { BottomNavigation, BottomNavigationAction } from '@mui/material';
import { Home as HomeIcon, AccountCircle as AccountCircleIcon } from '@mui/icons-material';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

const BottomNav = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm')); // Check if mobile view

  const handleChange = (event, newValue) => {
    // Redirect to different routes based on the tab selected
    switch (newValue) {
      case 0:
        break;
      case 1:
        break;
      default:
    }
  };

  // Determine which tab should be selected based on current route
  const getSelectedTab = () => {
    switch ('/') {
      case '/':
        return 0;
      case '/profile':
        return 1;
      default:
        return 0; // Default to Home tab
    }
  };

  return (
    <BottomNavigation
      value={getSelectedTab()}
      onChange={handleChange}
      showLabels
      sx={{
        position: 'fixed',
        bottom: 0,
        width: '100%', // Ensure it spans the full width
        zIndex: theme.zIndex.drawer + 1, // Ensure it's above other elements
      }}
    >
      <BottomNavigationAction label="Home" icon={<HomeIcon />} />
      <BottomNavigationAction label="Profile" icon={<AccountCircleIcon />} />
      <BottomNavigationAction label="Home" icon={<HomeIcon />} />
      <BottomNavigationAction label="Profile" icon={<AccountCircleIcon />} />
    </BottomNavigation>
  );
};

export default BottomNav;