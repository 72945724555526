// src/routes.js
import React from 'react';
import { Route, Routes } from 'react-router-dom';
// pages
import Home from './pages/Home';
import Login from './pages/Login';
import Locations from './pages/Locations';
import Barcodes from './pages/Barcodes';
import Profile from './pages/Profile';
import Payments from './pages/Payments';
// admin
import AdminDashboard from './pages/admin/AdminDashboard';
// crops
import TaskPlanning from './pages/field/TaskPlanning';

const AppRoutes = ({direction}) => {
  return (
    <Routes>
      <Route path="/login" element={<Login direction={direction}/>} />
      <Route path="/" element={<Home direction={direction}/>} />
      <Route path="/locations" element={<Locations direction={direction} />} />
      <Route path="/barcodes" element={<Barcodes direction={direction} />} />
      <Route path="/payments" element={<Payments direction={direction} />} />
      <Route path="/profile" element={<Profile direction={direction} />} />
      <Route path="/field/timeline" element={<TaskPlanning direction={direction} />} />
      <Route path="/admin/dashboard" element={<AdminDashboard direction={direction} />} />
    </Routes>
  );
};

export default AppRoutes;