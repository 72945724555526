import React, { useState } from 'react';
import {
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  Badge,
  Menu,
  MenuItem,
  ListItemText,
  ListItemIcon,
  Typography,
  Divider,
  Button,
} from '@mui/material';
import {
  Notifications as NotificationsIcon,
  Error,
  Water,
  Opacity,
  WifiOff,
  BatteryAlert,
  Cable,
  SyncProblem,
  Info,
  MoneyOff,
  Close
} from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

// Dummy data for notifications
const notifications = [
  {
    id: 2,
    type: 'max_water',
    seen: false,
    timestamp: 1688723100000,
    deviceId: 'Device2',
    location: 'Greenhouse 2',
  },
  {
    id: 3,
    type: 'min_water',
    seen: false,
    timestamp: 1688723400000,
    deviceId: 'Device3',
    location: 'Greenhouse 3',
  },
  {
    id: 4,
    type: 'controller_unvailable',
    seen: true,
    timestamp: 1688723700000,
    deviceId: 'Device4',
    location: 'Greenhouse 4',
  },
  {
    id: 5,
    type: 'low_battery',
    seen: false,
    timestamp: 1688724000000,
    deviceId: 'Device5',
    location: 'Greenhouse 5',
  },
  {
    id: 6,
    type: 'broken_wire',
    seen: false,
    timestamp: 1688724300000,
    deviceId: 'Device6',
    location: 'Greenhouse 6',
  },
  {
    id: 7,
    type: 'signal_issues',
    seen: false,
    timestamp: 1688724600000,
    deviceId: 'Device7',
    location: 'Greenhouse 7',
  },
  {
    id: 9,
    type: 'unpaid_bill',
    seen: false,
    timestamp: 1688724900000,
    deviceId: 'Device9',
    location: 'Greenhouse 9',
  },
];

// Icon mapping based on notification type
const notificationIcons = {
  alert: <Error />,
  max_water: <Water />,
  min_water: <Opacity />,
  controller_unvailable: <WifiOff />,
  low_battery: <BatteryAlert />,
  broken_wire: <Cable />,
  signal_issues: <SyncProblem />,
  general: <Info />,
  unpaid_bill: <MoneyOff />,
};

const NotificationMenu = () => {
  const { t } = useTranslation();
  const [anchorEl, setAnchorEl] = useState(null);
  const [openMobile, setOpenMobile] = useState(false);
  const navigate = useNavigate();

  const handleOpen = (event) => {
    if (window.innerWidth <= 600) {
      setOpenMobile(true);
    } else {
      setAnchorEl(event.currentTarget);
    }
  };

  const handleClose = () => {
    setAnchorEl(null);
    setOpenMobile(false);
  };

  const handleSeeAll = () => {
    navigate('/notifications');
    handleClose();
  };

  const handleNotificationClick = (id) => {
    navigate(`/notifications/${id}`);
    handleClose();
  };

  const unseenNotifications = notifications.filter((notification) => !notification.seen);

  const formatTimestamp = (timestamp) => {
    const date = new Date(timestamp);
    const dateOptions = {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
    };
    const timeOptions = {
      hour: '2-digit',
      minute: '2-digit',
      hour12: false,
    };
    const formattedDate = date.toLocaleDateString('en-GB', dateOptions);
    const formattedTime = date.toLocaleTimeString('en-GB', timeOptions);
    return `${formattedTime} ${formattedDate}`;
  };

  return (
    <>
      <IconButton
        color="inherit"
        aria-label="notifications"
        aria-controls="notification-menu"
        aria-haspopup="true"
        onClick={handleOpen}
      >
        <Badge badgeContent={unseenNotifications.length} color="error">
          <NotificationsIcon />
        </Badge>
      </IconButton>
      {window.innerWidth > 600 ? (
        <Menu
          id="notification-menu"
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={handleClose}
          MenuListProps={{
            'aria-labelledby': 'notification-button',
          }}
        >
          {unseenNotifications.slice(0, 5).map((notification) => (
            <div key={notification.id}>
              <MenuItem onClick={() => handleNotificationClick(notification.id)}>
                <ListItemIcon>
                  {notificationIcons[notification.type]}
                </ListItemIcon>
                <ListItemText
                  primary={
                    <>
                      <Typography variant="subtitle1">
                        {t(notification.type)}
                      </Typography>
                      <Typography variant="body2" color="textSecondary">
                        {formatTimestamp(notification.timestamp)}
                      </Typography>
                    </>
                  }
                  secondary={
                    <>
                      <Typography variant="body2">
                        {t(notification.type + '_desc')}
                      </Typography>
                      {notification.deviceId && (
                        <Typography variant="body2" color="textSecondary">
                          {t('controller')}: {notification.deviceId}
                        </Typography>
                      )}
                      {notification.location && (
                        <Typography variant="body2" color="textSecondary">
                          {t('location')}: {notification.location}
                        </Typography>
                      )}
                    </>
                  }
                />
              </MenuItem>
              <Divider />
            </div>
          ))}
          <MenuItem onClick={handleSeeAll}>
            <ListItemText primary={t('see_all_notifications')} />
          </MenuItem>
        </Menu>
      ) : (
        <Dialog
          fullScreen
          open={openMobile}
          onClose={handleClose}
          aria-labelledby="notification-dialog-title"
        >
          <DialogTitle id="notification-dialog-title">{t('notifications')}</DialogTitle>
          <DialogContent dividers>
            {unseenNotifications.map((notification) => (
              <div key={notification.id}>
                <ListItemText
                  primary={
                    <>
                      <ListItemIcon>
                        {notificationIcons[notification.type]}
                      </ListItemIcon>
                      <Typography variant="subtitle1">
                        {t(notification.type)}
                      </Typography>
                      <Typography variant="body2" color="textSecondary">
                        {formatTimestamp(notification.timestamp)}
                      </Typography>
                    </>
                  }
                  secondary={
                    <>
                      <Typography variant="body2">
                        {t(notification.type + '_desc')}
                      </Typography>
                      {notification.deviceId && (
                        <Typography variant="body2" color="textSecondary">
                          {t('controller')}: {notification.deviceId}
                        </Typography>
                      )}
                      {notification.location && (
                        <Typography variant="body2" color="textSecondary">
                          {t('location')}: {notification.location}
                        </Typography>
                      )}
                    </>
                  }
                />
                <Divider />
              </div>
            ))}
            <Button onClick={handleSeeAll} color="primary" fullWidth>
              {t('see_all_notifications')}
            </Button>
            <IconButton
              edge="end"
              color="inherit"
              onClick={handleClose}
              aria-label="close"
              sx={{
                position: 'absolute',
                top: 0,
                right: 0,
              }}
            >
              <Close />
            </IconButton>
          </DialogContent>
        </Dialog>
      )}
    </>
  );
};

export default NotificationMenu;