import React,{useState} from 'react';
import { Grid, Paper, Typography, Card, CardContent, Button, Box } from '@mui/material';
import { useTranslation } from 'react-i18next';

const handleLocationClick = (id) => {
  console.log(`Location ${id} clicked`);
};

const handleEditLocation = (id) => {
    console.log(`Location ${id} clicked`);
};

const LocationsList = ({locations}) => {
  const { t } = useTranslation(); // Assuming you are using translation keys
  const [locationsObject,setLocationsObject] = useState(locations);
  const [selectedState, setSelectedState] = useState(true); // Initial state set to 'normal' (true)
  const filteredLocations = locationsObject.filter(location => location.normal === selectedState);

  return (
    <Grid item xs={12} md={4}>
    <Paper elevation={3} sx={{ height: 'calc(70vh)', overflowY: 'auto', p: 2 }}>
      <Grid container spacing={2} sx={{ mb: 2 }}>
          <Grid item xs={6}>
            <Button
              fullWidth
              variant={selectedState ? "contained" : "outlined"} // Use selectedState to determine the variant
              onClick={() => setSelectedState(true)} // Set selectedState to true for 'normal'
            >
              {t('normal')}
            </Button>
          </Grid>
          <Grid item xs={6}>
            <Button
              fullWidth
              variant={selectedState ? "outlined" : "contained"} // Use selectedState to determine the variant
              onClick={() => setSelectedState(false)} // Set selectedState to false for 'abnormal'
            >
              {t('abnormal')}
            </Button>
          </Grid>
        </Grid>
        <Grid container spacing={2}>
          {filteredLocations.map((location) => (
            <Grid item xs={12} key={location.id}>
            <Card sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', p: 1 }}>
              <CardContent>
                <Typography variant="subtitle1" component="div">
                  {location.name}
                </Typography>
                <Typography variant="body2" color="textSecondary">
                  {t('cropType')}: {location.cropType}
                </Typography>
                <Typography variant="body2" color="textSecondary">
                  {t('locationType')}: {location.locationType}
                </Typography>
              </CardContent>
              <CardContent>
                <Box sx={{ display: 'flex' }}>
                  <Button
                    variant="outlined"
                    onClick={() => handleLocationClick(location.id)}
                    sx={{ mr: 1 }}
                  >
                    {t('view')}
                  </Button>
                  <Button
                    variant="outlined"
                    onClick={() => handleEditLocation(location.id)}
                  >
                    {t('edit')}
                  </Button>
                </Box>
              </CardContent>
            </Card>
          </Grid>
          ))}
        </Grid>
      </Paper>
      </Grid>
  );
};

export default LocationsList;