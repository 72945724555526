import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, TextField, Box, Typography, Grid, Alert, Snackbar, useMediaQuery } from '@mui/material';
import { useTranslation } from 'react-i18next';
import loginBackground from '../assets/images/background2.jpg'; // Make sure to have an image in this path
import logo from '../assets/images/logo_transparent.png'; // Make sure to have an image in this path

const Login = ({ setAuth, direction }) => {
  const isDesktop = useMediaQuery('(min-width: 1300px)'); // Adjust this breakpoint as needed
  const isMobile = useMediaQuery('(max-width: 600px)'); // Adjust this breakpoint as needed
  
  const { t } = useTranslation(); // Assuming you are using translation keys

  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const navigate = useNavigate();

  const handleLogin = () => {
    // Perform login logic here
    if (username === 'demo' && password === 'demo') {
      setAuth(true);
      navigate('/'); // Redirect to home after successful login
    } else {
      setOpenSnackbar(true); // Show error notification
    }
  };
  
  const BackgroundGridComponent = () => (
    <Grid
      item
      sm={4}
      md={7}
      sx={{
        backgroundImage: `url(${loginBackground})`,
        width: '100%',
        height: '100%',
        backgroundRepeat: 'no-repeat',
        backgroundColor: (t) => t.palette.mode === 'light' ? t.palette.grey[50] : t.palette.grey[900],
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        display: { xs: 'none', sm: 'block' }, // Hide on mobile screens
      }}
    />
  );

  const handleCloseSnackbar = () => {
    setOpenSnackbar(false);
  };

  return (
    <Grid container component="main" sx={{ height: '100vh', width: isMobile ? undefined : '100vw', margin: 0, padding: 0 }}>
      <Grid
        item
        xs={12}
        sm={8}
        md={5}
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            p: 3,
          }}
        >
          <Box
            component="img"
            sx={{
              height: isMobile ? 80 : 130, // Adjust the height for mobile
              mb: isMobile ? 5 : 10, // Adjust margin bottom for mobile
            }}
            alt="Logo"
            src={logo}
          />
          <Typography component="h1" variant={isMobile ? 'h6' : 'h5'}>
            {t('login')}
          </Typography>
          <Box component="form" noValidate  dir={direction}>
            <TextField
              margin="normal"
              required
              fullWidth
              id="username"
              label={t('username')}
              name="username"
              autoComplete="username"
              autoFocus
              value={username}
              onChange={(e) => setUsername(e.target.value)}
              size={isMobile ? 'small' : 'medium'} // Adjust text field size for mobile
            />
            <TextField
              margin="normal"
              required
              fullWidth
              name="password"
              label={t('password')}
              type="password"
              id="password"
              autoComplete="current-password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              size={isMobile ? 'small' : 'medium'} // Adjust text field size for mobile
            />
            <Button
              sx={{
                mt: 3, mb: 2, py: isMobile ? 1 : 2 
              }}
              type="button"
              fullWidth
              variant="contained"
              onClick={handleLogin}
            >
              {t('login')}
            </Button>
          </Box>
        </Box>
      </Grid>
      <BackgroundGridComponent />
      <Snackbar
        open={openSnackbar}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
      >
        <Alert onClose={handleCloseSnackbar} severity="error" sx={{ width: '100%' }}>
          Invalid credentials
        </Alert>
      </Snackbar>
    </Grid>
  );
};

export default Login;