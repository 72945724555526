import React, { useState, useEffect } from 'react';
import { Container, Grid, Paper, Typography, Box, TextField, Button, TableContainer, Table, TableHead, TableRow, TableCell, TableBody, MenuItem, Select, FormControl, InputLabel, TablePagination } from '@mui/material';
import ReactApexChart from 'react-apexcharts';
import axios from 'axios';
import dayjs from 'dayjs';
import { CSVLink } from 'react-csv';
import { useTranslation } from 'react-i18next';

// Sample data for demo purposes
const sampleData = {
  scans: [
    { id: 1, barcode: '1234567890123', date: '2023-06-18 09:00:00', weight: 50, location: "Palm tree 1", staff: "muhammad", category: "bonbon" },
    { id: 2, barcode: '2345678901234', date: '2023-06-18 10:00:00', weight: 75, location: "Palm tree 1", staff: "muhammad", category: "bonbon" },
    { id: 3, barcode: '3456789012345', date: '2023-06-18 11:00:00', weight: 65, location: "Palm tree 2", staff: "muhammad", category: "apple" },
    { id: 4, barcode: '4567890123456', date: '2023-06-18 12:00:00', weight: 80, location: "Palm tree 2", staff: "muhammad", category: "apple" },
  ],
  statistics: {
    totalToday: 4,
    totalYesterday: 3,
    netWeightToday: 270,
    netWeightYesterday: 200,
  },
  chart: {
    data: [
      { date: '2023-06-18 09:00:00', weight: 50 },
      { date: '2023-06-18 10:00:00', weight: 75 },
      { date: '2023-06-18 11:00:00', weight: 65 },
      { date: '2023-06-18 12:00:00', weight: 80 },
    ],
  },
};

const Barcodes = () => {
    const { t } = useTranslation(); // Assuming you are using translation keys
    const [barcodeData, setBarcodeData] = useState(sampleData.scans);
    const [filteredBarcodeData, setFilteredBarcodeData] = useState(sampleData.scans); // State to hold filtered data
    const [selectedDate, setSelectedDate] = useState(dayjs().format('YYYY-MM-DD'));
    const [statistics, setStatistics] = useState(sampleData.statistics);
    const [chartOptions, setChartOptions] = useState({
    chart: {
      id: 'weight-chart',
      type: 'line',
    },
    xaxis: {
      type: 'datetime',
      labels: {
        format: 'dd/MM/yyyy HH:mm:ss',
      },
    },
    yaxis: {
      title: {
        text: t('netWeightKg'),
      },
    },
  });
  const [chartSeries, setChartSeries] = useState([
    {
      name: 'Net Weight',
      data: sampleData.chart.data.map((item) => [new Date(item.date).getTime(), item.weight]),
    },
  ]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [selectedCategory, setSelectedCategory] = useState('');
  const [selectedLocation, setSelectedLocation] = useState('');
  
  useEffect(() => {
    // Initialize filtered data with all data on first load
    setFilteredBarcodeData(barcodeData);
  }, [barcodeData]);

  useEffect(() => {
    // Fetch data only if not using sample data
    if (selectedDate !== dayjs().format('YYYY-MM-DD')) {
      fetchData();
    }
  }, [selectedDate]);

  const fetchData = async () => {
    try {
      const { data } = await axios.get(`/api/barcodes?date=${selectedDate}`);
      setBarcodeData(data.scans);
      setStatistics(data.statistics);
      setChartSeries([
        {
          name: 'Net Weight',
          data: data.chart.data.map((item) => [new Date(item.date).getTime(), item.weight]),
        },
      ]);
    } catch (error) {
      console.error('Error fetching data', error);
    }
  };

  const handleDateChange = (event) => {
    setSelectedDate(event.target.value);
  };

  const handleFilterChange = (event) => {
    const { name, value } = event.target;
    filterData(name, value);
    console.log(event.target)
    if(event.target.name === "category"){
      setSelectedCategory(event.target.value);
    }else if(event.target.name === "location"){
      setSelectedLocation(event.target.value);
    }
  };

  const filterData = (filterField, filterValue) => {
    let filteredData = barcodeData.filter((item) => {
      if (filterValue === '') return true; // No filter applied
      return item[filterField].toLowerCase().includes(filterValue.toLowerCase());
    });
    setFilteredBarcodeData(filteredData);
    setPage(0); // Reset page to first page after filtering
  };

  const csvHeaders = [
    { label: t('id'), key: 'id' },
    { label: t('barcode'), key: 'barcode' },
    { label: t('date'), key: 'date' },
    { label: t('time'), key: 'time' },
    { label: t('weightKG'), key: 'weight' },
    { label: t('location'), key: 'location' },
    { label: t('staff'), key: 'staff' },
    { label: t('category'), key: 'category' },
  ];

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  return (
    <Container maxWidth="lg">
      <Box sx={{ mt: 4, mb: 4 }}>
      <Typography variant="h4" gutterBottom>
        {t('barcodeScanning')}
      </Typography>

      <Grid container spacing={3}>
        <Grid item xs={12} md={6}>
          <Paper sx={{ p: 2 }}>
            <Typography variant="h6">{t('selectDate')}</Typography>
            <TextField
              type="date"
              value={selectedDate}
              onChange={handleDateChange}
              fullWidth
            />
          </Paper>
        </Grid>

        <Grid item xs={12} md={6}>
          <Paper sx={{ p: 2 }}>
            <Typography variant="h6">{t('statistics')}</Typography>
            <Typography>{t('totalPalletsScannedToday')}: {statistics.totalToday}</Typography>
            <Typography>{t('totalPalletsScannedYesterday')}: {statistics.totalYesterday}</Typography>
            <Typography>{t('totalNetIncomingWeightToday')}: {statistics.netWeightToday} kg</Typography>
            <Typography>{t('totalNetIncomingWeightYesterday')}: {statistics.netWeightYesterday} kg</Typography>
          </Paper>
        </Grid>

        <Grid item xs={12}>
          <Paper sx={{ p: 2 }}>
            <Typography variant="h6">{t('scans')}</Typography>
            <Box sx={{ display: 'flex', justifyContent: 'flex-start', mb: 2, alignItems:'center'}}>
            <FormControl sx={{ mr: 3, minWidth: '150px' }} dir="rtl">
                <InputLabel style={{right:'30%'}}>
                  {t('category')}
                </InputLabel>
                <Select
                  value={selectedCategory}
                  onChange={(e) => handleFilterChange({ target: { name: 'category', value: e.target.value } })}
                >
                  <MenuItem value="">{t('all')}</MenuItem>
                  {Array.from(new Set(barcodeData.map((item) => item.category))).map((category, index) => (
                    <MenuItem key={index} value={category}>
                      {category}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
                <FormControl sx={{ mr: 3, minWidth: '150px'}}>
                  <InputLabel style={{right:'30%'}}>
                    {t('location')}
                  </InputLabel>
                    <Select
                    value={selectedLocation}
                    onChange={(e) => handleFilterChange({ target: { name: 'location', value: e.target.value } })}
                    >
                    <MenuItem value={''}>{t('all')}</MenuItem>
                    {Array.from(new Set(barcodeData.map((item) => item.location))).map((location, index) => (
                        <MenuItem key={index} value={location}>
                        {location}
                        </MenuItem>
                    ))}
                    </Select>
                </FormControl>
                <Button variant="contained" color="primary" sx={{ minWidth: '120px', height: '40px', ml: 2, marginRight:'1%' }}>
                    <CSVLink data={filteredBarcodeData} headers={csvHeaders} filename={`scans_${selectedDate}.csv`} style={{ textDecoration: 'none', color: 'white' }}>
                    {t('exportCSV')}
                    </CSVLink>
                </Button>
            </Box>
            <TableContainer component={Paper}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>{t('id')}</TableCell>
                    <TableCell>{t('barcode')}</TableCell>
                    <TableCell>{t('date')}</TableCell>
                    <TableCell>{t('time')}</TableCell>
                    <TableCell>{t('weightKG')}</TableCell>
                    <TableCell>{t('category')}</TableCell>
                    <TableCell>{t('staff')}</TableCell>
                    <TableCell>{t('location')}</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {filteredBarcodeData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((scan) => (
                    <TableRow key={scan.id}>
                      <TableCell>{scan.id}</TableCell>
                      <TableCell>{scan.barcode}</TableCell>
                      <TableCell>{scan.date.split(" ")[0]}</TableCell>
                      <TableCell>{scan.date.split(" ")[1]}</TableCell>
                      <TableCell>{scan.weight}</TableCell>
                      <TableCell>{scan.category}</TableCell>
                      <TableCell>{scan.staff}</TableCell>
                      <TableCell>{scan.location}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={[5, 10, 25]}
              component="div"
              count={filteredBarcodeData.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </Paper>
        </Grid>

        <Grid item xs={12}>
          <Paper sx={{ p: 2 }}>
            <Typography variant="h6">{t('netWeightOverTime')}</Typography>
            <ReactApexChart
              options={chartOptions}
              series={chartSeries}
              type="line"
              height={350}
            />
          </Paper>
        </Grid>
      </Grid>
    </Box>
    </Container>
  );
};

export default Barcodes;