import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Grid, Paper, Typography, Box, IconButton, List, ListItem, ListItemText, ListItemIcon, Divider, LinearProgress, Table, TableBody, TableCell, TableContainer, TableHead, TableRow
} from '@mui/material';
import { Add } from '@mui/icons-material';
import AddTaskModal from '../../components/field/AddTaskModal'; // Import the AddTaskModal component
import DatePicker from 'react-datepicker'; // Import DatePicker from react-datepicker
import 'react-datepicker/dist/react-datepicker.css'; // Import default styles

const stages = [
  { key: 'soil_preparation', label: 'soil_preparation' },
  { key: 'seedling_preparation', label: 'seedling_preparation' },
  { key: 'transplanting_preparation', label: 'transplanting_preparation' },
  { key: 'planting', label: 'planting' },
  { key: 'fertilizing', label: 'fertilizing' },
  { key: 'pruning', label: 'pruning' },
  { key: 'harvesting', label: 'harvesting' },
  { key: 'maintenance', label: 'maintenance' }
];

const months = [
  'jan', 'feb', 'mar', 'apr', 'may', 'jun', 'jul', 'aug', 'sep', 'oct', 'nov', 'dec'
];

const initialTasks = [
  { id: 1, block: 'Block F19', crop: 'Eggplant', urgent: true, stage: 'planting', startDate: new Date('2024-03-15').getTime(), endDate: new Date('2024-03-31').getTime() },
  { id: 2, block: 'Block R11', crop: 'Tomato (Heirloom)', urgent: true, stage: 'fertilizing', startDate: new Date('2024-04-10').getTime(), endDate: new Date('2024-04-30').getTime() },
  { id: 3, block: 'Block E23', crop: 'Tomato (Cherry)', urgent: false, stage: 'harvesting', startDate: new Date('2024-07-20').getTime(), endDate: new Date('2024-07-31').getTime() },
  { id: 4, block: 'Block L30', crop: 'Tomato (Heirloom)', urgent: false, stage: 'pruning', startDate: new Date('2024-05-05').getTime(), endDate: new Date('2024-05-31').getTime() },
];

const CropIcon = ({ crop }) => {
  // Placeholder for crop icons
  return null;
};

const calculateProgress = (startDate, endDate) => {
  const currentDate = new Date();
  const startTimestamp = new Date(startDate).getTime();
  const endTimestamp = new Date(endDate).getTime();
  
  if (currentDate < startTimestamp) return 0;
  if (currentDate > endTimestamp) return 100;
  
  const daysInTask = endTimestamp - startTimestamp;
  const daysElapsed = currentDate - startTimestamp;
  
  return Math.round((daysElapsed / daysInTask) * 100);
};

const TaskBlock = ({ task, onClick }) => {
  const progress = calculateProgress(task.startDate, task.endDate);

  return (
    <Box
      sx={{
        backgroundColor: task.urgent ? '#fce4e4' : '#f0f0f0',
        border: '1px solid #ccc',
        borderRadius: '4px',
        padding: '4px',
        width: '100%',
        height: '100%',
        boxSizing: 'border-box',
        cursor: 'pointer',
      }}
      onClick={onClick}
    >
      <Typography variant="body2" sx={{ fontWeight: 'bold' }}>{task.block}</Typography>
      <Typography variant="caption" color="textSecondary">{task.crop}</Typography>
      <LinearProgress variant="determinate" value={progress} sx={{ mt: 1 }} />
    </Box>
  );
};

const TaskPlanning = () => {
  const { t } = useTranslation();
  const [tasks, setTasks] = useState(initialTasks);
  const [selectedTask, setSelectedTask] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleTaskClick = (task) => {
    setSelectedTask(task);
  };

  const handleAddTask = (newTask) => {
    setTasks([...tasks, { ...newTask, id: tasks.length + 1 }]);
    setIsModalOpen(false); // Close modal after adding task
  };

  return (
    <Box sx={{ mt: 4, mb: 4 }}>
    <Grid container spacing={2}>
      <Grid item xs={12} md={8}>
        <Paper sx={{ padding: 2 }}>
          <Typography variant="h5" gutterBottom>
            {t('task_planning')}
          </Typography>
          <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <Typography variant="h6">{t('timeline')}</Typography>
            <IconButton color="primary" onClick={() => setIsModalOpen(true)}>
              <Add />
            </IconButton>
          </Box>
          <Box sx={{ p: 2 }}>
            <TableContainer component={Paper}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>{t('stage_and_period')}</TableCell>
                    {/* Render headers for each month */}
                    {months.map((month, index) => (
                      <TableCell key={month}>{t(month)}</TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {stages.map((stage) => (
                    <TableRow key={stage.key}>
                      <TableCell component="th" scope="row">
                        {t(stage.label)}
                      </TableCell>
                      {months.map((month, index) => (
                        <TableCell key={month} sx={{ position: 'relative', border: '1px solid #ccc', minHeight: '100px', verticalAlign: 'top' }}>
                          {tasks
                            .filter((task) => 
                              task.stage === stage.key &&
                              new Date(task.startDate).getMonth() <= index &&
                              new Date(task.endDate).getMonth() >= index
                            )
                            .map((task, taskIndex) => (
                              <TaskBlock key={task.id} task={task} onClick={() => handleTaskClick(task)} />
                            ))}
                        </TableCell>
                      ))}
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
        </Paper>
      </Grid>

      <Grid item xs={12} md={4}>
        <Paper sx={{ padding: 2 }}>
          <Typography variant="h5" gutterBottom>
            {selectedTask ? selectedTask.crop : ''}
          </Typography>
          <Typography variant="body2" color="textSecondary">
            {t('period')}: {selectedTask ? `${t(months[new Date(selectedTask.startDate).getMonth()])} 2024` : ''}
            &nbsp;-&nbsp;
            {selectedTask ? `${t(months[new Date(selectedTask.endDate).getMonth()])} 2024` : ''}
          </Typography>
          <Box sx={{ display: 'flex', alignItems: 'center', mt: 2 }}>
            <Typography variant="body2" color="textSecondary" sx={{ mr: 1 }}>
              {t('progress')}: {selectedTask ? `${calculateProgress(selectedTask.startDate, selectedTask.endDate)}%` : '0%'}
            </Typography>
            <LinearProgress variant="determinate" value={selectedTask ? calculateProgress(selectedTask.startDate, selectedTask.endDate) : 0} sx={{ flexGrow: 1 }} />
          </Box>
          <Divider sx={{ my: 2 }} />
          <Typography variant="h6" gutterBottom>
            {t('tasks')}
          </Typography>
          {/* Add subtitles for each stage */}
          {stages.map((stage) => (
            <Box key={stage.key} sx={{ mt: 2 }}>
              <Typography variant="subtitle1">{t(stage.label)}</Typography>
              <List>
                {tasks
                  .filter((task) => task.stage === stage.key)
                  .map((task, index) => (
                    <ListItem
                      key={task.id}
                      button
                      onClick={() => handleTaskClick(task)}
                      selected={selectedTask && selectedTask.id === task.id}
                      sx={{
                        mb: 2,
                        p: 2,
                        borderRadius: '4px',
                        backgroundColor: task.urgent ? '#fce4e4' : '#f0f0f0',
                      }}
                    >
                      <ListItemIcon>
                        <CropIcon crop={task.crop} />
                      </ListItemIcon>
                      <ListItemText
                        primaryTypographyProps={{ variant: 'body1', fontWeight: 'bold' }}
                        secondaryTypographyProps={{ variant: 'body2', color: 'textSecondary' }}
                        primary={task.block}
                        secondary={task.crop}
                      />
                      <Typography variant="body2" color="textSecondary">
                        {calculateProgress(task.startDate, task.endDate)}%
                      </Typography>
                    </ListItem>
                  ))}
              </List>
            </Box>
          ))}
        </Paper>
      </Grid>
      {/* Pass props to AddTaskModal */}
      <AddTaskModal open={isModalOpen} onClose={() => setIsModalOpen(false)} onSave={handleAddTask} />
    </Grid>
    </Box>
  );
};

export default TaskPlanning;