import React, { useEffect, useState } from 'react';
import { Tabs, Tab, Typography, Box, Grid, Paper, IconButton} from '@mui/material';
import ReactApexChart from 'react-apexcharts';
import { useTranslation } from 'react-i18next';
import { options as initialOptions, timelineOptions } from './configs/chartsOptions';
import GaugeComponent from './Gauge';

const ApexCharts = ({ node }) => {
  const { t } = useTranslation();
  const [controller, setController] = useState(node);
  const [loading, setLoading] = useState(true);
  const [value, setValue] = useState(0);
  const [data, setData] = useState({});
  const [groupedSensors, setGroupedSensors] = useState({});

  useEffect(() => {
    const fetchControllerCharts = async () => {
      const dataSample = {
        "id": 1,
        "name": "Controller 1",
        "location": "Field A",
        "sensors": [
          {
            "id": 101,
            "plug": "A",
            "minThreshold": 10,
            "maxThreshold": 80,
            "descType": "depth",
            "descText": 30,
            "type": "humidity",
            "currentValue":20,
            "data": [
              { "timestamp": "1486800000000", "value": 20 },
              { "timestamp": "1486886400000", "value": 30 },
              { "timestamp": "1486972800000", "value": 40 }
            ]
          },
          {
            "id": 102,
            "plug": "B",
            "minThreshold": null,
            "maxThreshold": null,
            "type": "humidity",
            "descType": "depth",
            "descText": 30,
            "currentValue":10,
            "data": [
              { "timestamp": "1486800000000", "value": 30 },
              { "timestamp": "1486886400000", "value": 40 },
              { "timestamp": "1486972800000", "value": 50 }
            ]
          },
          {
            "id": 101,
            "plug": "C",
            "minThreshold": null,
            "maxThreshold": null,
            "type": "watermark",
            "descType": "depth",
            "descText": 30,
            "currentValue":0,
            "data": [
              { "timestamp": "1486800000000", "value": 25 },
              { "timestamp": "1486886400000", "value": 26 },
              { "timestamp": "1486972800000", "value": 27 }
            ]
          },
          {
            "id": 102,
            "plug": "D",
            "minThreshold": 70,
            "maxThreshold": 80,
            "type": "watermark",
            "descType": "depth",
            "descText": 30,
            "currentValue":0,
            "data": [
              { "timestamp": "1486800000000", "value": 25 },
              { "timestamp": "1486886400000", "value": 26 },
              { "timestamp": "1486972800000", "value": 27 }
            ]
          }
        ]
      };

      setData(dataSample);

      const grouped = dataSample.sensors.reduce((acc, sensor) => {
        if (!acc[sensor.type]) {
          acc[sensor.type] = [];
        }
        acc[sensor.type].push(sensor);
        return acc;
      }, {});

      setGroupedSensors(grouped);
      setLoading(false);
    };

    fetchControllerCharts();
  }, [t]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const renderTabs = () => {
    return Object.keys(groupedSensors).map((type, index) => (
      <Tab key={index} label={<Typography variant="body1">{t(type)}</Typography>} id={`tab-${index}`} aria-controls={`tabpanel-${index}`} />
    ));
  };

  const renderSeriesData = (type) => {
    const sensorsOfType = groupedSensors[type];
    return sensorsOfType.map(sensor => ({
      name: `${t('sensor')} ${sensor.plug} - ${t(sensor.descType)} ${sensor.descText}`,
      data: sensor.data.map(point => ({ x: parseInt(point.timestamp, 10), y: point.value }))
    }));
  };

  const generateAnnotations = (type) => {
    const sensorsOfType = groupedSensors[type];
    const annotations = sensorsOfType.reduce((acc, sensor) => {
      if (sensor.minThreshold !== null) {
        acc.push({
          y: sensor.minThreshold,
          borderColor: '#00e396',
          borderWidth: 2, 
          label: {
            position: 'relative', 
            borderColor: '#00e396',
            borderWidth: 0, 
            style: {
              color: '#fff',
              background: '#00e396',
            },
            text: `${t('minThreshold')}: ${sensor.minThreshold}`
          }
        });
      }
      if (sensor.maxThreshold !== null) {
        acc.push({
          y: sensor.maxThreshold,
          borderColor: '#e3004d',
          borderWidth: 2, 
          label: {
            position: 'relative', 
            borderColor: '#e3004d',
            borderWidth: 0, 
            style: {
              color: '#fff',
              background: '#e3004d',
            },
            text: `${t('maxThreshold')}: ${sensor.maxThreshold}`
          }
        });
      }
      return acc;
    }, []);
    return annotations;
  };

  const handleSettingsClick = (sensor) => {
    
  }

  const renderChart = (type) => {
    const chartOptions = { ...initialOptions };
    chartOptions.annotations = { yaxis: generateAnnotations(type) };

    const sensorsOfType = groupedSensors[type];

    return (
      <>
      <Typography variant="h6" style={{marginTop:'10px'}} gutterBottom>
          {t('currentData')}
      </Typography>
      <Grid container spacing={4} alignItems="center" justifyContent="center">
          {sensorsOfType.map((sensor, index) => (
            <Grid item xs={12 / sensorsOfType.length} key={index}>
                {/* Gauge Component */}
                <GaugeComponent
                  sensor={sensor}
                  sensorName={`${t('sensor')} ${sensor.plug} - ${t(sensor.descType)} ${sensor.descText}`}
                />
            </Grid>
          ))}
        </Grid>
        <Typography variant="h6" style={{marginTop:'10px'}} gutterBottom>
          {t('dataComparison')}
        </Typography>
        <Grid container spacing={2} style={{alignItems:'center',justifyContent:'center'}}>
        {/* Line Chart */}
        <Grid item xs={12}>
          <ReactApexChart
            style={{ marginTop: '10px' }}
            options={chartOptions}
            series={renderSeriesData(type)} // Assuming renderSeriesData is defined elsewhere
            type="line"
            width={'108%'}
            //height={300} // Adjust height as needed
          />
        </Grid>

        {/* Timeline */}
        <Grid item xs={12}>
          <Typography variant="h6" gutterBottom>
            {t('timeline')}
          </Typography>
          <ReactApexChart
            options={timelineOptions}
            series={renderSeriesData(type)} // Assuming renderSeriesData is defined elsewhere
            type="area"
            height={130} // Adjust height as needed
            width="100%"
          />
        </Grid>
      </Grid>
      </>
    );
  };

  if (!loading) {
    return (
      <Box sx={{ width: '100%' }}>
        <Tabs value={value} onChange={handleChange} centered aria-label="Sensor Tabs">
          {renderTabs()}
        </Tabs>
        {Object.keys(groupedSensors).map((type, index) => (
          <TabPanel key={index} value={value} index={index}>
            {renderChart(type)}
          </TabPanel>
        ))}
      </Box>
    );
  } else {
    return (
      <Box sx={{ width: '100%' }}>
        {/* Optional loading indicator can be added here */}
      </Box>
    );
  }
};

const TabPanel = (props) => {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`tabpanel-${index}`}
      aria-labelledby={`tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
};

export default ApexCharts;