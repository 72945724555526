import React, { useRef, useState, useEffect } from 'react';
import { Grid, Typography, Box, Container, Button, Modal, TextField, MenuItem, Select, FormControl, InputLabel, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, IconButton, ListItemIcon } from '@mui/material';
import Map from '../components/Map';
import { useTranslation } from 'react-i18next';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import AppleIcon from '@mui/icons-material/Apple'; // Import the fruit icon

const Locations = () => {
  const { t } = useTranslation(); // Assuming you are using translation keys
  const locationsContainerRef = useRef(null);
  const [containerWidth, setContainerWidth] = useState(0);
  const [open, setOpen] = useState(false);
  const [locationName, setLocationName] = useState('');
  const [landSize, setLandSize] = useState('');
  const [locationType, setLocationType] = useState('');
  const [locationDetails, setLocationDetails] = useState('');
  const [locations, setLocations] = useState([]); // State to hold locations

  // Sample initial locations data
  useEffect(() => {
    // Simulating initial data
    setLocations([
      { id: 1, name: 'Location 1', type: 'greenhouse', details: 'Growing tomatoes', coordinates: [[31.76600526438945, 34.791737794876106], [31.764654505550048, 34.79113698005677]] },
      { id: 2, name: 'Location 2', type: 'openfield', details: 'Growing corn', coordinates: [[31.768, 34.779], [31.767, 34.780]] },
    ]);
  }, []);

  const updateContainerWidth = () => {
    if (locationsContainerRef.current) {
      setContainerWidth(locationsContainerRef.current.clientWidth);
    }
  };

  useEffect(() => {
    updateContainerWidth();
    window.addEventListener('resize', updateContainerWidth);
    return () => {
      window.removeEventListener('resize', updateContainerWidth);
    };
  }, []);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const handleAddLocation = () => {
    // Create a new location object
    const newLocation = {
      id: locations.length + 1,
      name: locationName,
      type: locationType,
      details: locationDetails,
      coordinates: [] // You can add coordinates here if needed
    };
    // Add the new location to the locations array
    setLocations([...locations, newLocation]);
    // Reset form fields
    setLocationName('');
    setLandSize('');
    setLocationType('');
    setLocationDetails('');
    handleClose();
  };

  const handleTableRowClick = (location) => {
    // Handle row click to highlight location on the map or vice versa
    console.log('Clicked location:', location);
    // Implement logic to interact with the map (highlighting, centering, etc.)
    // For now, you can just log the clicked location details
  };

  const handleEditLocation = (id) => {
    // Implement edit logic based on location id
    console.log('Edit location with id:', id);
  };

  const handleDeleteLocation = (id) => {
    // Implement delete logic based on location id
    console.log('Delete location with id:', id);
    const updatedLocations = locations.filter((loc) => loc.id !== id);
    setLocations(updatedLocations);
  };

  return (
      <Box sx={{ mt: 4, mb: 4 }}>
    <Grid container spacing={2}>
      <Grid item xs={12} md={6}>
        <Button variant="contained" color="primary" onClick={handleOpen} style={{ marginTop: '2%' }}>
          {t('addNewLocation')}
        </Button>
        <Box sx={{ mt: 4, mb: 2 }}>
          <Map createdCallback={(e) => console.log('Drawing:', e)} MapWidth={12} />
        </Box>
      </Grid>
      <Grid item xs={12} md={6}>
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>{t('locationName')}</TableCell>
                <TableCell>{t('locationType')}</TableCell>
                <TableCell>{t('locationDetails')}</TableCell>
                <TableCell>{t('actions')}</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {locations.map((location) => (
                <TableRow key={location.id} onClick={() => handleTableRowClick(location)} style={{ cursor: 'pointer' }}>
                  <TableCell>
                    <ListItemIcon>
                      <AppleIcon /> {/* Fruit icon */}
                    </ListItemIcon>
                    {location.name}
                  </TableCell>
                  <TableCell>{location.type}</TableCell>
                  <TableCell>{location.details}</TableCell>
                  <TableCell>
                    <IconButton aria-label="edit" onClick={() => handleEditLocation(location.id)}>
                      <EditIcon />
                    </IconButton>
                    <IconButton aria-label="delete" onClick={() => handleDeleteLocation(location.id)}>
                      <DeleteIcon />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>

      <Modal open={open} onClose={handleClose}>
        <Box sx={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', width: 400, bgcolor: 'background.paper', borderRadius: 1, boxShadow: 24, p: 4 }}>
          <Typography variant="h6" component="h2" sx={{ mb: 2 }}>
            {t('addNewLocation')}
          </Typography>
          <FormControl fullWidth sx={{ mb: 2 }}>
            <TextField label={t('locationName')} value={locationName} onChange={(e) => setLocationName(e.target.value)} />
          </FormControl>
          <FormControl fullWidth sx={{ mb: 2 }}>
            <TextField label={t('landSize')} value={landSize} onChange={(e) => setLandSize(e.target.value)} />
          </FormControl>
          <FormControl fullWidth sx={{ mb: 2 }}>
            <InputLabel>{t('locationType')}</InputLabel>
            <Select value={locationType} onChange={(e) => setLocationType(e.target.value)} label={t('locationType')}>
              <MenuItem value="greenhouse">{t('greenhouse')}</MenuItem>
              <MenuItem value="openfield">{t('openfield')}</MenuItem>
              <MenuItem value="indoor">{t('indoor')}</MenuItem>
            </Select>
          </FormControl>
          <FormControl fullWidth sx={{ mb: 2 }}>
            <TextField label={t('locationDetails')} value={locationDetails} onChange={(e) => setLocationDetails(e.target.value)} />
          </FormControl>
          <Box sx={{ textAlign: 'right' }}>
            <Button onClick={handleAddLocation} variant="contained" color="primary">
              {t('addLocation')}
            </Button>
          </Box>
        </Box>
      </Modal>
    </Grid>
    </Box>
  );
};

export default Locations;