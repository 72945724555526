import React, { useState } from 'react';
import { TableContainer, TableCell, TableRow, Table, TableHead, TableBody, TablePagination, Container, Box, Grid, Paper, Typography, TextField, Button } from '@mui/material';
import { BarChart, Bar, LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import { useTranslation } from 'react-i18next';

const PaymentsPage = () => {
  const { t } = useTranslation(); // Assuming you are using translation keys
  // Example state for invoices
  const [invoices, setInvoices] = useState([
    { id: 1, invoiceNumber: 'INV-001', amount: 100, status: 'unpaid', timestamp: 1625680800000 }, // July 7, 2021, 10:00:00 AM UTC
    { id: 2, invoiceNumber: 'INV-002', amount: 150, status: 'paid', timestamp: 1625767200000 }, // July 8, 2021, 10:00:00 AM UTC
    { id: 3, invoiceNumber: 'INV-003', amount: 120, status: 'unpaid', timestamp: 1625853600000 }, // July 9, 2021, 10:00:00 AM UTC
    { id: 4, invoiceNumber: 'INV-004', amount: 180, status: 'unpaid', timestamp: 1625940000000 }, // July 10, 2021, 10:00:00 AM UTC
    { id: 5, invoiceNumber: 'INV-005', amount: 200, status: 'paid', timestamp: 1626026400000 }, // July 11, 2021, 10:00:00 AM UTC
    { id: 6, invoiceNumber: 'INV-006', amount: 250, status: 'unpaid', timestamp: 1626112800000 }, // July 12, 2021, 10:00:00 AM UTC
    { id: 7, invoiceNumber: 'INV-007', amount: 300, status: 'unpaid', timestamp: 1626199200000 }, // July 13, 2021, 10:00:00 AM UTC
    // Add more invoices as needed
  ]);

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [searchTerm, setSearchTerm] = useState('');

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const filteredInvoices = invoices.filter(invoice =>
    invoice.invoiceNumber.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const markAsPaid = (id) => {
    const updatedInvoices = invoices.map(inv =>
      inv.id === id ? { ...inv, status: t('waitingForConfirmation') } : inv
    );
    setInvoices(updatedInvoices);
  };

  const viewInvoice = (id) => {
    // Implement logic to view detailed invoice information
    console.log(`View invoice ${id}`);
  };

  // Calculate total amounts
  const totalPaid = invoices.reduce((total, inv) => inv.status === 'paid' ? total + inv.amount : total, 0);
  const totalUnpaid = invoices.reduce((total, inv) => inv.status === 'unpaid' ? total + inv.amount : total, 0);

  // Data for example bar chart
  const barChartData = [
    { name: t('paid'), amount: totalPaid },
    { name: t('unpaid'), amount: totalUnpaid },
  ];

  // Data for example line chart
  const lineChartData = invoices.map(inv => ({
    timestamp: new Date(inv.timestamp), // Convert timestamp to Date object
    amount: inv.amount,
    status: inv.status,
  }));

  return (
    <Container maxWidth="lg">
      <Box sx={{ mt: 4, mb: 4 }}>
        <Grid container spacing={3}>
          {/* Right Section: Visualization or Widgets */}
          <Grid item xs={12} md={6}>
            <Paper style={{ padding: 20 }}>
              <Typography variant="h5" gutterBottom>
                {t('paymentsOverTime')}
              </Typography>
              <ResponsiveContainer width="100%" height={300}>
                <LineChart data={lineChartData} margin={{ top: 20, right: 30, left: 0, bottom: 5 }}>
                  <CartesianGrid strokeDasharray="3 3" />
                  <XAxis dataKey="timestamp" tickFormatter={(date) => new Date(date).toLocaleDateString()} />
                  <YAxis />
                  <Tooltip />
                  <Legend />
                  <Line type="monotone" dataKey="amount" name={t('amount')} stroke="#82ca9d" />
                </LineChart>
              </ResponsiveContainer>
            </Paper>
          </Grid>
          <Grid item xs={12} md={6}>
            <Paper style={{ padding: 20 }}>
              {/* Total amounts */}
              <Typography variant="body1" gutterBottom>
                {t('totalPaid')}: ${totalPaid}
              </Typography>
              <Typography variant="body1" gutterBottom>
                {t('totalUnpaid')}: ${totalUnpaid}
              </Typography>

              {/* Example Bar Chart */}
              <ResponsiveContainer width="100%" height={300}>
                <BarChart data={barChartData} margin={{ top: 20, right: 30, left: 0, bottom: 5 }}>
                  <CartesianGrid strokeDasharray="3 3" />
                  <XAxis dataKey="name" />
                  <YAxis />
                  <Tooltip />
                  <Legend />
                  <Bar dataKey="amount" name={t('amount')} fill="#8884d8" />
                </BarChart>
              </ResponsiveContainer>
            </Paper>
          </Grid>

          {/* Left Section: Table of Invoices */}
          <Grid item xs={12}>
            <Paper style={{ padding: 20 }}>
              <Typography variant="h5" gutterBottom>
                {t('payment')}
              </Typography>

              {/* Search field */}
              <TextField
                label={t('searchInvoice')}
                variant="outlined"
                size="small"
                fullWidth
                margin="normal"
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
              />

              <TableContainer component={Paper} style={{ marginTop: 20 }}>
                <Table>
                  {/* Table headers */}
                  <TableHead>
                    <TableRow>
                      <TableCell>{t('invoiceNumber')}</TableCell>
                      <TableCell>{t('amount')}</TableCell>
                      <TableCell>{t('status')}</TableCell>
                      <TableCell>{t('actions')}</TableCell>
                    </TableRow>
                  </TableHead>
                  {/* Table body */}
                  <TableBody>
                    {filteredInvoices.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map(invoice => (
                      <TableRow key={invoice.id}>
                        <TableCell>{invoice.invoiceNumber}</TableCell>
                        <TableCell>${invoice.amount}</TableCell>
                        <TableCell>{t(invoice.status)}</TableCell>
                        <TableCell>
                        {invoice.status === 'unpaid' && (
                            <Button
                              variant="contained"
                              color="primary"
                              onClick={() => markAsPaid(invoice.id)}
                            >
                              {t('markAsPaid')}
                            </Button>
                          )}
                          <Button
                            style={{marginRight:'3%'}}
                            variant="outlined"
                            color="primary"
                            onClick={() => viewInvoice(invoice.id)}
                          >
                            {t('viewInvoice')}
                          </Button>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>

              {/* Pagination */}
              <TablePagination
                rowsPerPageOptions={[5, 10, 25]}
                component="div"
                count={filteredInvoices.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </Paper>
          </Grid>
        </Grid>
      </Box>
    </Container>
  );
};

export default PaymentsPage;