import React, { useState } from 'react';
import { MapContainer, TileLayer, Marker, Popup, Rectangle, FeatureGroup } from 'react-leaflet';
import { Box, Paper, Grid } from '@mui/material';
import 'leaflet/dist/leaflet.css';
import 'leaflet-draw/dist/leaflet.draw.css';
import { EditControl } from 'react-leaflet-draw';
import { useTranslation } from 'react-i18next';

import '../assets/css/Map.css';

const Map = ({MapWidth,createdCallback}) => {
    const { t } = useTranslation(); // Assuming you are using translation keys
    const [markers, setMarkers] = useState([]);
    const startingPosition = [31.7680463, 34.778182]; // Custom starting position (New York City)

    // Define rectangles with their bounds
    const fields = [
        {
            id: 1,
            name: 'test',
            bounds: [
                [31.76600526438945, 34.791737794876106],
                [31.764654505550048, 34.79113698005677]
            ]
        },
    ];

    const handleCreated = (e) => {
        const { layerType, layer } = e;
        if (layerType === 'rectangle') {
            const bounds = layer.getBounds();
            console.log(bounds);
            // You can add your logic here to mark a specific location within the rectangle or save the bounds
        }
        createdCallback(e);
    };

    return (
        <Grid item xs={12} md={MapWidth}>
            <Paper elevation={3} sx={{ p: 2, height: '100%' }}>
                <Box sx={{ width: '100%' }}>
                    <Box sx={{ height: '80vh', width: '100%' }}>
                        <MapContainer attributionControl={false} center={startingPosition} zoom={13} scrollWheelZoom={false} style={{ height: '100%', width: '100%' }}>
                            <TileLayer
                                url="https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}"
                                attribution='Tiles &copy; Esri &mdash; Source: Esri, i-cubed, USDA, USGS, AEX, GeoEye, Getmapping, Aerogrid, IGN, IGP, UPR-EGP, and the GIS User Community'
                            />
                            <FeatureGroup>
                                <EditControl
                                    position="topright"
                                    onCreated={handleCreated}
                                    draw={{
                                        rectangle: true,
                                        polyline: true,
                                        polygon: true,
                                        circle: false,
                                        marker: false,
                                        circlemarker: false,
                                    }}
                                />
                                {fields.map(field => (
                                    <Rectangle
                                        key={field.id}
                                        bounds={field.bounds}
                                        color="blue"
                                        fillOpacity={0.5}
                                    >
                                        <Popup>
                                            {t('locationName')}: {field.name}
                                        </Popup>
                                    </Rectangle>
                                ))}
                            </FeatureGroup>
                        </MapContainer>
                    </Box>
                </Box>
            </Paper>
        </Grid>
    );
};

export default Map;