const timelineOptions = {
    chart: {
      id: 'timeline',
      //height: 130,
      type: 'area',
      brush: {
        enabled: true,
        target: 'main'
      },
      selection: {
        enabled: true,
        xaxis: {
          min: 1486800000000,
          max: 1487145600000
        }
      }
    },
    colors: ['#008FFB', '#546E7A'],
    xaxis: {
      type: 'datetime',
      labels: {
        format: 'dd/MM/yyyy HH:mm:ss'
      },
      tooltip: {
        enabled: false
      }
    },
    yaxis: {
      tickAmount: 2
    }
};
  
let options = {
    chart: {
      id: 'main',
      height: 350,
      type: 'line',
      zoom: {
        enabled: true
      },
      toolbar: {
        show: true,
        tools: {
          download: true,
          selection: true,
          zoom: true,
          zoomin: true,
          zoomout: true,
          pan: true,
          reset: true
        }
      }
    },
    colors: ['#008FFB', '#546E7A'],
    dataLabels: {
      enabled: false
    },
    stroke: {
      curve: 'smooth'
    },
    xaxis: {
      type: 'datetime',
      labels: {
        format: 'dd/MM/yyyy HH:mm:ss'
      }
    },
    yaxis: {
      min: 0,
      max: 200,
      labels: {
        formatter: function (val) {
          return val.toFixed(2);
        }
      },
    },
    tooltip: {
      x: {
        format: 'dd/MM/yyyy HH:mm:ss'
      },
    },
    markers: {
      size: 4,
      colors: ['#008FFB', '#546E7A'],
      strokeColors: '#fff',
      strokeWidth: 2,
      hover: {
        size: 7,
      }
    },
    annotations:{
      yaxis:[]
    }
};

export { options, timelineOptions };
