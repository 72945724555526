import React from 'react';
import { useTranslation } from 'react-i18next';
import { Paper, Typography, IconButton } from '@mui/material';
import SettingsIcon from '@mui/icons-material/Settings';

const GaugeComponent = ({ sensor, sensorName }) => {
  const { t } = useTranslation(); // Assuming you are using translation keys

  // Determine color based on thresholds
  let backgroundColor = 'green';
  // Determine label text based on value position relative to thresholds
  let labelText = '';
  if (sensor.currentValue < sensor.minThreshold) {
    labelText = t('belowThreshold');
    backgroundColor = '#ff9800'; // Orange color from Material-UI
  } else if (sensor.currentValue >= sensor.maxThreshold) {
    labelText = t('aboveThreshold');
    backgroundColor = '#f44336'; // Red color from Material-UI
  } else {
    labelText = t('normal');
    backgroundColor = '#4caf50'; // Green color from Material-UI
  }

  return (
    <Paper
      style={{
        backgroundColor: backgroundColor,
        color: 'white',
        textAlign: 'center',
        borderRadius: '8px',
        position: 'relative', // Added relative positioning
        padding: '10px', // Added padding to avoid overlap with the icon
      }}
    >
      <IconButton
        style={{ position: 'absolute', top: '0px', right: '0px' }} // Adjusted position
        aria-label="settings"
        size="small"
        onClick={() => {}}
      >
        <SettingsIcon style={{ color: 'white' }} />
      </IconButton>
      <Typography variant="subtitle2">{sensorName}</Typography>
      <Typography variant="h6">
        {sensor.currentValue} {t('centibar')}
      </Typography>
    </Paper>
  );
};

export default GaugeComponent;