import React, { useRef, useState, useEffect } from 'react';
import { Container, Grid, Paper, Typography, TextField, IconButton, List, ListItem, ListItemText, ListItemIcon, Box, Tabs, Tab, CircularProgress } from '@mui/material';
import { Dashboard, Settings, Search, Edit, Payment, LocationOn, SensorDoor, AttachMoney, Person, DataUsage } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';

const AdminDashboard = () => {
  const { t } = useTranslation(); // Assuming you are using translation keys
  const [selectedTab, setSelectedTab] = useState(0);
  const [searchTerm, setSearchTerm] = useState('');
  const [users, setUsers] = useState([]);
  const [selectedUser, setSelectedUser] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const handleTabChange = (event, newValue) => {
    setSelectedTab(newValue);
  };

  const handleSearchChange = async (event) => {
    setSearchTerm(event.target.value);
    setIsLoading(true);
    try {
      // Simulate API call to fetch users
      // const response = await fetch(`https://api.example.com/users?q=${event.target.value}`);
      // const data = await response.json();
      setUsers([{ "name": "Roni", "id": 1, "email": "roni@roni.io" }]); // Example response
    } catch (error) {
      console.error('Error fetching users:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleUserSelect = (user) => {
    setSelectedUser(user);
    setUsers([]);
    setSearchTerm('');
  };

  return (
    <Container maxWidth="lg">
      <Grid container spacing={2} sx={{ mt: 4 }}>
        {/* Billing Details */}
        <Grid item xs={12} md={6} lg={4}>
          <Paper sx={{ p: 2 }}>
            <Typography variant="h6" gutterBottom>
              {t('billingStats')}
            </Typography>
            <List>
              <ListItem>
                <ListItemIcon>
                  <AttachMoney />
                </ListItemIcon>
                <Typography variant="h5" sx={{ ml: 1 }}>0</Typography> {/* Adjust the variant and spacing here */}
                <ListItemText primary={t('paidInvoices')} secondary={t('paidInvoicesDesc')} />
              </ListItem>
              <ListItem>
                <ListItemIcon>
                  <Payment />
                </ListItemIcon>
                <Typography variant="h5" sx={{ ml: 1 }}>0</Typography> {/* Adjust the variant and spacing here */}
                <ListItemText primary={t('unpaidInvoices')} secondary={t('unpaidInvoicesDesc')}/>
              </ListItem>
            </List>
          </Paper>
        </Grid>


        {/* User Selection and Information */}
        <Grid item xs={12} md={6} lg={8}>
          <Paper sx={{ p: 2 }}>
            <Typography variant="h6" gutterBottom>
              {t('selectUser')}
            </Typography>
            <TextField
              variant="outlined"
              fullWidth
              placeholder={t('searchUserPlaceholder')}
              value={searchTerm}
              onChange={handleSearchChange}
              InputProps={{
                endAdornment: (
                  <IconButton>
                    <Search />
                  </IconButton>
                ),
              }}
            />
            {isLoading ? (
              <Box sx={{ display: 'flex', justifyContent: 'center', mt: 2 }}>
                <CircularProgress />
              </Box>
            ) : (
              <List>
                {users.map((user) => (
                  <ListItem button key={user.id} onClick={() => handleUserSelect(user)}>
                    <ListItemText primary={user.name} secondary={user.email} />
                  </ListItem>
                ))}
              </List>
            )}
          </Paper>
          {selectedUser && (
            <Paper sx={{ p: 2, mt: 2 }}>
              <Typography variant="h6" gutterBottom>
                {t('userInformation')}
              </Typography>
              <Typography variant="subtitle1">{t('name')}: {selectedUser.name}</Typography>
              <Typography variant="body2">{t('email')}: {selectedUser.email}</Typography>
              {/* Add more user details here */}
            </Paper>
          )}
        </Grid>

        {/* Tabs */}
        <Grid item xs={12}>
          <Paper sx={{ p: 2 }}>
            <Tabs value={selectedTab} onChange={handleTabChange} variant="fullWidth">
              <Tab label={t('locations')} icon={<LocationOn />} />
              <Tab label={t('controllers')} icon={<SensorDoor />} />
              <Tab label={t('sensors')} icon={<Settings />} />
              <Tab label={t('dataUsage')} icon={<DataUsage />} />
              <Tab label={t('billing')} icon={<AttachMoney />} />
            </Tabs>
          </Paper>
          {/* Tab Content */}
          <Paper sx={{ p: 2, mt: 2 }}>
            {/* Render content based on selectedTab */}
            {selectedTab === 0 && (
              <Box>
                <Typography variant="h6">{t('locations')}</Typography>
                {/* Placeholder list of locations */}
              </Box>
            )}
            {selectedTab === 1 && (
              <Box>
                <Typography variant="h6">{t('controllers')}</Typography>
                {/* Placeholder list of controllers */}
              </Box>
            )}
            {selectedTab === 2 && (
              <Box>
                <Typography variant="h6">{t('sensors')}</Typography>
                {/* Placeholder list of sensors */}
              </Box>
            )}
            {selectedTab === 3 && (
              <Box>
                <Typography variant="h6">{t('dataUsage')}</Typography>
                {/* Placeholder chart for data usage */}
              </Box>
            )}
            {selectedTab === 4 && (
              <Box>
                <Typography variant="h6">{t('billing')}</Typography>
                {/* Placeholder chart for data usage */}
              </Box>
            )}
          </Paper>
        </Grid>
      </Grid>
    </Container>
  );
};

export default AdminDashboard;